import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { LocalStorage } from './LocalStorage';
import { environment } from './../../environments/environment';

@Injectable()
export class HttpHelpUtils {
  public http: HttpClient;
  public storage: LocalStorage;

  public loadingText = '加载中';
  public loadingBlock = false;
  public loadingGif = "assets/file/image/loading-b.gif";
  public pageHeight = 'calc(100vh - 102px)';                // 页面header高度
  public menuWidth = '240px';                               // 侧边栏宽度
  public dialogBlock = false;

  constructor(
    http: HttpClient,
    storage: LocalStorage) {
    this.http = http;
    this.storage = storage;
  }

  checkFullPath(url: string) {
    if (url.indexOf('https') != -1 || url.indexOf('https') != -1) {
      return url;
    }
    let domain = '';
    if (url.startsWith('sys/')) {
        domain += 'system/';
    } else if (url.startsWith('psg/')) {
        domain += 'psgsys/';
    }
    return environment.baseUrl + domain + url;
  }

  getError(error): {} {
    let returnOption = {code: 2};
    switch (error.status) {
      case 403:
        returnOption['message'] = '您得权限不足，服务器拒绝访问';
        break;
      case 404:
        returnOption['message'] = '服务器未响应当前地址，请联系客服人员反馈';
        break;
      case 504:
        returnOption['message'] = '您好：服务器正在重启，请稍后重试！';
        break;
      default:
        returnOption['message'] = '【'+ error.status +'】未知错误，请联系管理员！';
        break;
    }
    return returnOption;
  }

    // get
    public get(url: string, params?: any) {
        let httpParams = new HttpParams();
        if (params) {
            if (params['page']) {
                params['page'] += 1
            } else {
                params['page'] = 1;
            }
            params['size'] = params['rows'];
            params['current'] = params['page'];
            for (const key in params) {
                if (params[key] === false || params[key] || params[key] === 0) {
                    httpParams = httpParams.set(key, params[key].toString().trim());
                }
            }
        }

        let req = {
            params: httpParams
        };
        return Promise.race([new Promise((resolve, reject) => {
            this.http.get(this.checkFullPath(url), req).subscribe(
                res => {
                    resolve(res)
                },
                error => {
                    resolve(this.getError(error))
                }
            )
        }),
        new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({code: 99, message: '访问已超时'})
            }, 50000);
        })]);
    }

    // post
    public post(url: string, data?: object) {
        let tempData = {};
        tempData = data;
        if(url!='auth/login'){
            if (tempData['page']) {
                tempData['page'] += 1
            } else {
                tempData['page'] = 1;
            }
            tempData['size'] = tempData['rows'];
            tempData['current'] = tempData['page'];
        }
        for (const key in tempData) {
            if (tempData[key] && tempData[key] != 'undefined' ) {
                if ((typeof tempData[key]) == 'string') {
                    tempData[key] = tempData[key].toString().trim();
                }
            }
        }
        return Promise.race([
            new Promise((resolve, reject) => {
                this.http.post(this.checkFullPath(url), tempData).subscribe(
                res => {
                    resolve(res)
                },
                error => {
                    resolve(this.getError(error))
                }
                )
            }),
            new Promise((resolve, reject) => {
                setTimeout(() => {
                resolve({code: 99, message: '访问已超时'})
                }, 30000);
            })
        ]);
    }


    // 保存
    public save({ url=null, validateForm=null, noForm=false, options={}, confirmMsg=null, confirmTitle=null, success=null, error=null,  
                confirm = null, subUrl = null, formType = null, successMsg='' }): void {
        if (!noForm) {
            if (validateForm) {
                if (validateForm.controls.length !== 0) {
                for (const i in validateForm.controls) {
                    if (validateForm.controls[i].invalid) {
                    validateForm.controls[i].markAsDirty();
                    validateForm.controls[i].updateValueAndValidity();
                    return;
                    }
                    options[i] = validateForm.controls[i].value;
                }
                } else {
                return
                }
            }
        }
        confirm.confirm({
            message: confirmMsg || '确定保存数据吗？',
            icon: 'pi pi-exclamation-triangle',
            header: confirmTitle || '保存确认',
            acceptLabel: '确定',
            rejectLabel: '取消',
            rejectButtonStyleClass: 'p-button-raised p-button-text p-button-plain',
            accept: () => {
                this.loadingBlock = true;
                subUrl = (subUrl == null ? '' : subUrl);
                this.post(url + subUrl, options).then(res => {
                    success && success(res)
                }).catch(err => {
                    console.log(err);
                });
            },
            reject: () => {

            },
            key: 'confirm'
        });
    }

     // 删除
     public deleteById({ url=null, deleteId=null, confirmMsg=null, confirmTitle=null, success=null, error=null, confirm = null,successMsg=''  }): void {
        const that = this;
        confirm.confirm({
            message: confirmMsg || '确定删除选中的数据吗？',
            icon: 'pi pi-exclamation-triangle',
            header: confirmTitle || '删除确认',
            acceptLabel: '确定',
            rejectLabel: '取消',
            rejectButtonStyleClass: 'p-button-raised p-button-text p-button-plain',
            accept: () => {
                that.loadingBlock = true;
                that.get(url, { id: deleteId }).then(res => {
                success && success(res)
                }).catch(err => {
                console.log(err);
                });
            },
            reject: () => {

            },
            key: 'confirm'
        });
    }

    // 删除
    public delete({ url=null, deleteId=null, confirmMsg=null, confirmTitle=null, success=null, error=null, confirm = null,successMsg=''  }): void {
        const that = this;
        confirm.confirm({
            message: confirmMsg || '确定删除选中的数据吗？',
            icon: 'pi pi-exclamation-triangle',
            header: confirmTitle || '删除确认',
            acceptLabel: '确定',
            rejectLabel: '取消',
            rejectButtonStyleClass: 'p-button-raised p-button-text p-button-plain',
            accept: () => {
                that.loadingBlock = true;
                that.get(url, { ids: deleteId }).then(res => {
                success && success(res)
                }).catch(err => {
                console.log(err);
                });
            },
            reject: () => {

            },
            key: 'confirm'
        });
    }

    // 审核
    public check({ url=null, deleteId=null, confirmMsg=null, confirmTitle=null, success=null, error=null, confirm = null,successMsg=''  }): void {
        const that = this;
        confirm.confirm({
            message: confirmMsg || '确定审核通过选中的数据吗？',
            icon: 'pi pi-exclamation-triangle',
            header: confirmTitle || '审核确认',
            acceptLabel: '确定',
            rejectLabel: '取消',
            rejectButtonStyleClass: 'p-button-raised p-button-text p-button-plain',
            accept: () => {
                that.loadingBlock = true;
                that.get(url, { ids: deleteId }).then(res => {
                    success && success(res)
                }).catch(err => {
                    console.log(err);
                });
            },
            reject: () => {

            },
            key: 'confirm'
        });
    }

    // 作废——by zwb 2024-08-28 add
    public cancel({ url=null, cancelId=null, confirmMsg=null, confirmTitle=null, success=null, error=null, confirm = null,successMsg=''  }): void {
        const that = this;
        confirm.confirm({
            message: confirmMsg || '确定作废选中的数据吗？',
            icon: 'pi pi-exclamation-triangle',
            header: confirmTitle || '作废确认',
            acceptLabel: '确定',
            rejectLabel: '取消',
            rejectButtonStyleClass: 'p-button-raised p-button-text p-button-plain',
            accept: () => {
                that.loadingBlock = true;
                that.get(url, { id: cancelId }).then(res => {
                success && success(res)
                }).catch(err => {
                console.log(err);
                });
            },
            reject: () => {

            },
            key: 'confirm'
        });
    }


   
}
