// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true, // 打包之前改为ture
  tokenKey: "psgToken", // 缓存key
  baseUrl: 'http://127.0.0.1:4200/api/',            // 打包之前改为正式domain地址

  //  baseUrl: 'https://landdev.sujiawuliu.cn:8443/api/',
    // baseUrl: "https://land.sujiawuliu.cn/api/",
         // 扫码打开司机二维码             生产
  driverQCodeUrl: "https://newcar.51bkx.cn/otms/driver/", // 扫码打开司机二维码              测试
  // driverBulkQCodeUrl: 'https://pfm.51hahy.com/otms/bulkOrder/',      // 扫码打开司机二维码(大宗货源码)   生产
  driverBulkQCodeUrl: "https://newcar.51bkx.cn/otms/bulkOrder/", // 扫码打开司机二维码(大宗货源码)    测试
  // teamQCodeUrl: 'https://pfm.51hahy.com/otms/team/',                 // 扫码打开车主二维码             生产
  teamQCodeUrl: "https://newcar.51bkx.cn/otms/team/", // 扫码打开车主二维码              测试
  // shipperQCodeUrl: 'https://pfm.51hahy.com/otms/shipper/',           // 扫码打开货主二维码             生产
  shipperQCodeUrl: "https://newcar.51bkx.cn/otms/shipper/", // 扫码打开货主二维码              测试
  // qCodeUrl: 'http://weixinislajiyou.natapp4.cc/api',                  // 本地调试域名
  // qCodeUrl: 'https://pfm.51hahy.com/api/',                           // 线上地址域名
  qCodeUrl: "https://newcar.51bkx.cn/api/",
  //qCodeUrl: 'http://rydtest.natapp1.cc/api/',
  downUrl: "https://newcar.51bkx.cn/otms",
  driverPublishQCodeUrl: "https://newcar.51bkx.cn/otms/publish/", // 扫码打开询价二维码
  // driverPublishQCodeUrl: 'https://pfm.51hahy.com/otms/publish/', // 生产扫码打开询价二维码
  // downUrl:'https://pfm.51hahy.com/otms',
  mode: "prod", // 打包之前改为 prod
   tenantId: '400831',
  //  tenantId: "547815",
  qType:'trial'//正式版为 "release"，体验版为 "trial"，开发版为 "develop"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
