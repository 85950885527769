import { Component, ElementRef, OnInit, ViewChild, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, transition, animate, AnimationEvent, query, state, stagger } from '@angular/animations';
import { HttpHelpUtils } from '../../../utils/HttpHelpUtils';
import { LocalStorage } from '../../../utils/LocalStorage';
import { SessionStorage } from '../../../utils/SessionStorage';
import { BaseCommpont } from 'src/app/utils/BaseCommpont';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CusRouteReuseStrategy } from '../../../utils/CusRouteReuseStrategy';
import { ConfirmationService, MessageService } from 'primeng/api';
import { fromEvent, observable, Observable } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from '../../../utils/Animations';
import { MessageSub } from './../../../utils/MessageSub';
import { PlatformLocation } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { CommonMessageService } from './../../../utils/Message.service';
@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    providers: [MessageService, ConfirmationService],
    animations: [
        trigger("inOutPaneAnimation", [
            transition(":enter", [
                style({ opacity: 0, transform: "translateX(-100%)", backgroundColor: "var(--surface-a)" }), //apply default styles before animation starts
                animate(
                    "750ms ease-in-out",
                    style({ opacity: 1, transform: "translateX(0)", backgroundColor: "var(--surface-a)" })
                )
            ]),
            transition(":leave", [
                style({ opacity: 1, transform: "translateX(0)", backgroundColor: "var(--surface-a)" }), //apply default styles before animation starts
                animate(
                    "1600ms ease-in-out",
                    style({ opacity: 0, transform: "translateX(100%)", backgroundColor: "var(--surface-a)" })
                )
            ])
        ]),
        trigger("showPaneAnimation", [
            transition(":enter", [
                query(':enter', [
                    style({ opacity: 0, transform: "translateX(-100%)" }), //apply default styles before animation starts
                    stagger('50ms', [
                        animate(
                            "2s ease-in-out", style({ opacity: 1, transform: "translateX(0)" })
                        )
                    ])
                ], { optional: true })
            ]),
            transition(":leave", [
                query(':leave', [
                    style({ opacity: 1, transform: "translateX(0)" }), //apply default styles before animation starts
                    stagger('100ms', [
                        animate(
                            "150ms ease-in-out", style({ opacity: 0, transform: "translateX(100)" })
                        )
                    ])
                ], { optional: true })
            ])
        ]),
        trigger('overlayMenuAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scaleY(0.8)' }),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
            ]),
            transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
            ])
        ]),
        slideInAnimation
    ]
})
export class LayoutComponent implements OnInit {
    validateForm: FormGroup;
    chooseForm: FormGroup;
    bc = new BaseCommpont();

    @ViewChild('userOperate') userOperate: ElementRef;
    @ViewChild('subMneu') subMneu: ElementRef;
    menuWidth = '240px';
    selectMenuId = 0;
    activeCitemName = '';
    toggleUserInfo = false;
    toggleBoxInfo = false;
    showSetThemeFlag = false;
    personalDetailsForm: FormGroup;
    themeType = '';
    dataType = '';
    headerSetType = '';
    updatePwdFlag = false;
    personalDetailsFlag = false;
    // username = '';
    // realname = '';
    // companyName = '';
    loginData = {};
    formToken = '';                                                     // 表单formToken
    formId = '';                                                        // 表单formId
    menuList = [];
    baseMenuList = [];
    shipperId = null;
    showQCodeFlag = false                                               // 货主码
    showBulkOrderQCodeFlag = false                                      // 货源码
    favoriteManuList = [];
    favoriteMenuMap = {};
    tabMenu: any[] = [{ menuname: '首页', id: 0, link: 'home' }];
    menuHeight = 'calc(100% - 64px)';

    windowResizeFlag = false;

    loginAgentId: any;
    loginCustId: any;

    checkLoginStatus = false;

    satelliteOptions: any;
    is_shipper: any
    isShowShipper = true
    sizeList = [
        { size: '12px', toggle: false },
        { size: '13px', toggle: false },
        { size: '14px', toggle: true },
        { size: '15px', toggle: false },
        { size: '16px', toggle: false }
    ]

    sideButtonItems = [
        {
            icon: 'pi pi-phone',
            command: () => {
                
            }
        },
        {
            icon: 'pi pi-envelope',
            command: () => {
                
            }
        }, {
            icon: 'pi pi-window-maximize',
            command: () => {

            }
        }
    ];

    //默认行数
    defaultRows = 20;
    // 默认查询时间区间, 单位天
    searchRange = 15;

    constructor(
        private router: Router,
        public httpHelp: HttpHelpUtils,
        private localStorage: LocalStorage,
        private sessionStorage: SessionStorage,
        private fb: FormBuilder,
        private elementRef: ElementRef,
        private messageService: MessageService,
        private configSrv: ConfirmationService,
        private messageSub: MessageSub,
        private platformLocation: PlatformLocation,
        private commMessService: CommonMessageService
    ) {
        this.platformLocation.onPopState(() => {
            this.router.navigate(['login']);
        });
    }

    // 侧边栏调整
    toggleSilderMenu() {
        this.menuWidth = (this.menuWidth == '240px' ? '64px' : '240px');
        this.httpHelp.menuWidth = this.menuWidth;
    }

    toggleMenu(item, mini?) {
        this.menuList.map(i => {
            if (item['menuname'] == i['menuname']) {
                i['toggle'] = mini || !i['toggle'];
            } else {
                i.toggle = false;
            }
        })
    }

     /**
     * 页面跳转激活tab
     * @param citem
     */
      navegateUrl(citem, item?) {
        try{
            if(citem.link=='dataAnaly/waybillAnaly/waybillAnaly.html'){
                window.open(environment.downUrl+'/analy/');
                // var a = document.createElement('a')
                // a.href = 'https://pfm.51hahy.com/otms/analy';
                // a.download = '运单分析'
                // a.target = '_blank'
                // a.click() 
                return
            }
        }catch(e){}
        if (item) {
            this.toggleMenu(this.menuItem, true);
        }
        this.activeCitemName = citem.title;
        let commonUrl = '';
        if (this.loginAgentId == 0 && this.loginCustId != 0) {
            commonUrl = 'shipper/';
        }

        if (this.loginAgentId != 0 && this.loginCustId == 0) {
            commonUrl = 'agent/';
        }

        this.router.navigate([commonUrl + citem.link]).then(res => {
            let tabMenuAddFlag = true;
            this.tabMenu.forEach((e, i) => {
                if (e.id === citem.id) {
                    tabMenuAddFlag = false;
                }
            });
            if (tabMenuAddFlag) {
                this.tabMenu.push(citem);
            }
            this.selectMenuId = citem.id;
        });
    }

    subMenus = [];
    showSubMenu = false;
    menuItem = {};
    onSubMenuOpen(item) {
        this.menuItem = item;
        this.menuList.map(i => {
            if (item['menuname'] == i['menuname']) {
                i['min-toggle'] = true;
                this.subMenus = item['children'];
                this.showSubMenu = true;
                this.bindSubmenuOutsideClickListener(this.outsideSubmenuClickListencer);
            } else {
                i['min-toggle'] = false;
            }
        });
    }

    onHideSubmenu() {
        this.showSubMenu = false;
        this.unbindOutsideClickListener(this.outsideSubmenuClickListencer);
    }

    showUserInfo(event) {
        this.toggleUserInfo = true;
        this.bindOutsideClickListener(this.outsideClickListener);
        event.preventDefault();
    }

    showBoxInfo(event) {
        this.toggleBoxInfo = true;
        event.preventDefault();
    }

    // 显示联系方式
    showContractPhone() {
        
    }

    outsideClickListener: any;
    outsideSubmenuClickListencer: any;

    isOutsideTopbarMenuClicked(event): boolean {
        return !(this.userOperate.nativeElement.isSameNode(event.target) || this.userOperate.nativeElement.contains(event.target));
    }

    isOutsideLeftMenuClicked(event): boolean {
        if (!this.subMneu || !this.subMneu.nativeElement) {
            return;
        }
        return !(this.subMneu.nativeElement.isSameNode(event.target) || this.subMneu.nativeElement.contains(event.target));
    }

    bindOutsideClickListener(listener) {
        if (!listener) {
            listener = (event) => {
                if (this.isOutsideTopbarMenuClicked(event)) {
                    this.toggleUserInfo = false;
                    this.unbindOutsideClickListener(listener);
                }
            };
            document.addEventListener('click', listener);
        }
    }

    bindSubmenuOutsideClickListener(listener) {
        if (!listener) {
            listener = (event) => {
                if (this.isOutsideLeftMenuClicked(event)) {
                    this.showSubMenu = false;
                    this.unbindOutsideClickListener(listener);
                }
            };
            document.addEventListener('click', listener);
        }
    }

    unbindOutsideClickListener(listener) {
        if (listener) {
            document.removeEventListener('click', listener);
            listener = null;
        }
    }

    chooseSize(item) {
        this.sizeList.map(i => {
            if (i['size'] === item['size']) {
                i['toggle'] = true;
            } else {
                i['toggle'] = false;
            }
        });
        document.documentElement.style.fontSize = item['size'];
        this.localStorage.set('theme-size', item['size']);
    }

    // 选择设置喜欢的菜单
    chooseFavorite(item, event) {
        let tempFlag = true;
        this.favoriteManuList.forEach(e => {
            if (e['id'] == item['id']) {
                tempFlag = false;
            }
        });
        if (tempFlag) {
            this.favoriteManuList.push(item);
        }
        this.favoriteMenuMap = [];
        this.favoriteManuList.forEach(e => {
            this.favoriteMenuMap[e['id']] = true;
        });
        this.menuList = [...[{
            menuname: '常用菜单',
            icon: 'pi pi-star',
            children: this.favoriteManuList
        }], ...this.baseMenuList];
        this.localStorage.setObject("favoriteMenuList", this.favoriteManuList);
        event.stopPropagation();
    }

    // 取消设置某一个喜欢菜单
    removeChooseFavorite(item, event, menuname) {
        this.favoriteManuList = this.favoriteManuList.filter(e => e['id'] !== item['id']);
        this.favoriteMenuMap = {};
        this.favoriteManuList.forEach(e => {
            this.favoriteMenuMap[e['id']] = true;
        });
        if (this.favoriteManuList.length == 0) {
            this.menuList = this.baseMenuList;
        } else {
            this.menuList = [...[{
                menuname: '常用菜单',
                icon: 'pi pi-star',
                toggle: menuname == '常用菜单' ? true : false,
                children: this.favoriteManuList
            }], ...this.baseMenuList];
        }

        this.localStorage.setObject("favoriteMenuList", this.favoriteManuList);
        event.stopPropagation();
    }

    themeMap = {
        'light': 'assets/theme/res-theme/bootstrap4-light-purple.css',
        'dark': 'assets/theme/res-theme/bootstrap4-dark-purple.css',
        'light-blue': 'assets/theme/res-theme/bootstrap4-light-blue.css'
    }

    // 切换主题
    changeTheme(type: string) {
        const linkElement = document.getElementById('theme-link');
        const origThemeType = this.localStorage.get('theme-type');

        linkElement.setAttribute('href', linkElement.getAttribute('href').replace(this.themeMap[origThemeType],
            this.themeMap[type]));

        if (type.indexOf('light') != 0) {
            this.httpHelp.loadingGif = 'assets/file/image/loading-w.gif';
        } else {
            this.httpHelp.loadingGif = 'assets/file/image/loading-w.gif';
        }
        this.themeType = type;
        this.localStorage.set('theme-type', type);
    }

    // 切换数据格式
    changeDataType(dataType) {
        this.localStorage.set('data-type', dataType);
        this.dataType = dataType;
    }

    // 切换头部是否隐藏
    changeHeaderSet(headerType) {
        this.localStorage.set('header-set-type', headerType);
        if (headerType == 'show') {
            this.httpHelp.pageHeight = 'calc(100vh - 102px)';
            this.menuHeight = 'calc(100% - 64px)';
        } else {
            this.httpHelp.pageHeight = 'calc(100vh - 38px)';
            this.menuHeight = 'calc(100% - 194px)';
        }
        this.headerSetType = headerType;
    }

    // 默认行数设置
    changeDefaultRowsSet(rows) {
        this.localStorage.set('table-rows-type', rows + '');
        this.defaultRows = rows;
    }

    // 默认查询天数范围
    changeDefaultSearchRange(days) {
        this.localStorage.set('search-range', days + '');
        this.searchRange = days;
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet?.activatedRouteData?.['animation'];
    }

    handleChange(item) {
        this.selectMenuId = item.id;
        let commonUrl = '';
        if (this.loginAgentId == 0 && this.loginCustId != 0 && item.link != 'home') {
            commonUrl = 'shipper/';
        }

        if (this.loginAgentId != 0 && this.loginCustId == 0 && item.link != 'home') {
            commonUrl = 'agent/';
        }
        this.router.navigate([commonUrl + item.link]);
    }

    // 用户信息
    queryUserInfo() {
        this.personalDetailsFlag = true;
        // const userInfo = {
        //     id: 489,
        //     url: 'userInfo/userInfo.html',
        //     title: '个人设置',
        //     icon: '',
        // };
        // this.navegateUrl(userInfo);
    }

    handleDelete(item, $event) {
        $event.stopPropagation();
        $event.preventDefault();
        if (item.id === this.selectMenuId) {
            this.selectMenuId = 0;
            this.router.navigate(['home']);
        }
        this.tabMenu = this.tabMenu.filter(e => e.id !== item.id);
        let commonUrl = '';
        if (this.loginAgentId == 0 && this.loginCustId != 0) {
            commonUrl = 'shipper/';
        }

        if (this.loginAgentId != 0 && this.loginCustId == 0) {
            commonUrl = 'agent/'
        }

        CusRouteReuseStrategy.handlers[commonUrl + item.link] = null;
        CusRouteReuseStrategy.closeTabUrl = item.link;
    }

    swPlatform() {
        this.router.navigate(['swichPlate']);
    }

    // skipHome() {
    //   this.menu.forEach((e, i) => {
    //     this.menu[i].active = false;
    //   });
    //   this.activeCitemName = '';
    //   this.selectMenuId = 0;
    //   this.router.navigate(['home']);
    //   this.skipHomeFlag = true;
    // }

    resizeWindow() {
        this.windowResizeFlag = !this.windowResizeFlag;
        const el = document.body;
        const isFullscreen = document.fullscreenElement;
        if (!isFullscreen) {
          if (el.requestFullscreen) {
            el.requestFullscreen();
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
        }
    }
    // showQCdoe() {
    //   const that = this;
    //   this.showQCodeFlag = true;
    //   this.qCodeValue = this.tempQCodeUrl + this.branch_id;
    // }
    downloadQCdoe() {
        // const that = this;
        // html2canvas(document.getElementById('qrcode')).then((canvas) => {
        //   const canvasImg = canvas.toDataURL('image/png');
        //   const a = document.createElement('a');
        //   a.href = canvasImg;
        //   a.download = '发车方二维码';
        //   a.click();
        // });
    }
    // // 修改密码
    updateUserPwd() {
        this.validateForm.reset();
        this.updatePwdFlag = true;
        this.httpHelp.get('psg/common/get-form-token', {}).then(res => {
            if (res['status'] == 'success') {
                this.formToken = res['data']['formToken'];
                this.formId = res['data']['formId'];
            } else {

            }
        });
    }

    saveUserPwd() {
        const password = this.validateForm.controls.oldPassword.value;
        const new_password = this.validateForm.controls.newPassword.value;
        const repeat_password = this.validateForm.controls.newPassword1.value;
        if (password == null) {
            //   this.httpHelp.message.add({ key: 'tc', severity: 'error', detail: '请输入旧密码！' });
            this.bc.showMessage(this.messageSub, '请输入旧密码！', 'error');
            return;
        }
        if (new_password == null) {
            // this.httpHelp.message.add({ key: 'tc', severity: 'error', detail: '请输入新密码！' });
            this.bc.showMessage(this.messageSub, '请输入新密码！', 'error');
            return;
        }
        if (repeat_password == null) {
            // this.httpHelp.message.add({ key: 'tc', severity: 'error', detail: '请输入确认密码！' });
            this.bc.showMessage(this.messageSub, '请输入确认密码！', 'error');
            return;
        }
        if (this.validateForm.controls.newPassword.value !== this.validateForm.controls.newPassword1.value) {
            // this.httpHelp.message.add({ key: 'tc', severity: 'error', detail: '两次密码不一致' });
            this.bc.showMessage(this.messageSub, '两次密码不一致', 'error');
            return;
        }
        const pattern = /^(?![^a-zA-Z]+$)(?!\D+$).{6,20}/;
        if (!pattern.test(new_password) || !pattern.test(repeat_password)) {
            // this.httpHelp.messageSub.add({ key: 'tc', severity: 'error', detail: '密码必须包含字母、数字以及特殊字符且不少于8位' });
            this.bc.showMessage(this.messageSub, '密码必须包含字母、数字以及特殊字符且不少于8位', 'error');
            return;
        }
        const options = {
            token: this.formToken,
            formId: this.formId
        };
        for (const i in this.validateForm.controls) {
            if (this.validateForm.controls[i].invalid) {
                this.validateForm.controls[i].markAsDirty();
                this.validateForm.controls[i].updateValueAndValidity();
                return;
            }
            options[i] = this.validateForm.controls[i].value;
        }

        this.configSrv.confirm({
            message: '确定保存数据吗？',
            icon: 'pi pi-exclamation-triangle',
            header: '保存确认',
            acceptLabel: '确定',
            rejectLabel: '取消',
            rejectButtonStyleClass: 'p-button-raised p-button-text p-button-plain',
            accept: () => {
                this.httpHelp.loadingBlock = true;
                this.httpHelp.post('sys/user/update-password', options).then(res => {
                    this.httpHelp.loadingBlock = false;
                    if (res['status'] == 'success') {
                        
                        this.updatePwdFlag = false;
                        // this.localStorage.remove(environment.tokenKey);
                        // this.httpHelp.message.add({ key: 'tc', severity: 'success', detail: '密码修改成功，请重新登陆' });
                        this.router.navigate(['login']);
                    } else {
                        this.bc.showMessage(this.messageSub, res['message'], 'error');
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            reject: () => {

            },
            key: 'confirmResetPassword'
        });
    }

    // 退出登录，清空路由数据
    logout() {
        this.sessionStorage.remove(environment.tokenKey);
        this.sessionStorage.remove("is_shipper");
        this.router.navigate(['login']);
    }

    // 加载页面初始配置
    // data=[];
    loadPageInfoConfig() {
        const that = this;
        this.httpHelp.get('sys/menu/routes', {}).then(res => {
            setTimeout(() => {
                this.checkLoginStatus = true;
            }, 1000);
            if (res['status'] == 'success') {
                that.baseMenuList = res['data']['list'];
                if (this.favoriteManuList.length != 0) {
                    that.menuList = [...[{
                        menuname: '常用菜单',
                        icon: 'pi pi-star',
                        children: this.favoriteManuList
                    }], ...res['data']['list']];
                    that.menuList.forEach((e, i) => {
                        if (i == 0) {
                            e['toggle'] = true;
                            e['icon'] = 'pi pi-star';
                        } else {
                            e['toggle'] = false;
                        }
                    });
                } else {
                    that.menuList = res['data']['list'];
                    that.menuList.forEach(e => {
                        e['toggle'] = false;
                    });
                }
            } else {
                if (res['defailCode'] == 3003) {
                    that.router.navigate(['/login']);
                }
            }
        });
    }

    // 获取登录用户信息
    getLoginUserInfo() {
        this.httpHelp.get('psg/common/get-login-user', {}).then(res => {
            if (res['status'] == 'success') {
                this.loginData = res['data'];
                this.localStorage.setObject("loginData", this.loginData);
                
                this.loginAgentId = res['data']['agentId'];
                this.loginCustId = res['data']['custId'];
                this.sessionStorage.setItem("is_shipper", this.loginCustId);
            } else {
                this.sessionStorage.setItem("is_shipper", '1');
            }
            // 判断账号是否货主端
            this.is_shipper = this.sessionStorage.getItem("is_shipper")
            if (this.is_shipper == 0) {
                this.isShowShipper = false;
            }
        });
    }

    qCodeValue = '';                        // 货主码
    orderqCodeValue = '';                   // 货源码
    elementType = NgxQrcodeElementTypes.IMG;
    correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

    // 展示货主码
    showShipperQcode() {
        const that = this;
        // this.shipperBulkOrderQCodeDetail = item;
        that.qCodeValue = environment.driverQCodeUrl + 'shipper_id-' + this.shipperId;
        that.showQCodeFlag = true;
    }

    // 展示货源码
    showShipperOrderQcode() {
        const that = this;
        // this.shipperBulkOrderQCodeDetail = item;
        that.orderqCodeValue = environment.shipperQCodeUrl + 'bulk_no-' + this.shipperId;
        that.showBulkOrderQCodeFlag = true;
    }

    showMsg(msgData: {}) {
        this.messageService.add({
            key: msgData['key'] ? msgData['key'] : 'showMsg',
            severity: msgData['messageType'] ? msgData['messageType'] : 'success',
            detail: msgData['messageDetail'] ? msgData['messageDetail'] : '保存成功！'
        });
    }

    ngOnInit(): void {
        // 获取登录用户信息
        this.getLoginUserInfo();
        // 获取二维码相关
        this.httpHelp.get('psg/shipper/get-shipper', {}).then(res => {
            if (res['status'] == 'success') {
                if (res['data']) {
                    this.shipperId = res['data']['id'];
                }
            } else {

            }
        });

        // 初始化主题配置
        const size = this.localStorage.get('theme-size') || '14px';
        this.sizeList.map(i => {
            if (i['size'] == size) {
                i.toggle = true;
            } else {
                i.toggle = false;
            }
        });
        this.themeType = this.localStorage.get('theme-type');

        // 初始化选择菜单，动态获取菜单列表之后，根据缓存中是否有对应值比对
        const menuData = this.localStorage.getObject("favoriteMenuList");
        if (menuData['noData'] == "null") {
            this.favoriteManuList = [];
        } else {
            this.favoriteManuList = this.localStorage.getObject("favoriteMenuList");
            this.favoriteMenuMap = {};
            this.favoriteManuList.forEach(e => {
                this.favoriteMenuMap[e['id']] = true;
            });
        }

        this.dataType = this.localStorage.get('data-type') || 'table';

        this.headerSetType = this.localStorage.get('header-set-type') || 'show';
        if (this.headerSetType == 'show') {
            this.httpHelp.pageHeight = 'calc(100vh - 102px)';
            this.menuHeight = 'calc(100% - 64px)';
        } else {
            this.httpHelp.pageHeight = 'calc(100vh - 38px)';
            this.menuHeight = 'calc(100% - 194px)';
        }

        this.defaultRows = Number(this.localStorage.get('table-rows-type') || 50) ;

        this.searchRange = Number(this.localStorage.get('search-range') || 15) ;

        // 加载页面菜单列表
        this.loadPageInfoConfig();

        // 监听宽度改变
        this.menuWidth = window.innerWidth <= 1680 ? '64px' : '240px';
        fromEvent(window, 'resize').subscribe((e) => {
            this.menuWidth = window.innerWidth <= 1680 ? '64px' : '240px';
            this.httpHelp.menuWidth = this.menuWidth;
        });
        this.validateForm = this.fb.group({
            // oldPassword: [null, [Validators.required]],
            // newPassword: [null, [Validators.required, Validators.pattern('^(?![^a-zA-Z]+$)(?!\D+$).{8,20}')]],
            // newPassword1: [null, [Validators.required, Validators.pattern('^(?![^a-zA-Z]+$)(?!\D+$).{8,20}')]]
            oldPassword: [null],
            newPassword: [null],
            newPassword1: [null]
        });
        // 防止页面刷新路由不跳转
        this.router.navigate(['home']);

        // 路由地址跳转
        let urlObserver: Observable<any> = Observable.create((observable) => {
            let data = this.commMessService.get('payLoad');
            this.activeCitemName = data.title;
            data.menuname = data.title
            data.link = data.url
            this.router.navigate([data.url]).then(res => {
              let tabMenuAddFlag = true;
              this.tabMenu.forEach((e, i) => {
                if (e['id'] === data['id']) {
                  tabMenuAddFlag = false;
                }
              });
              if (tabMenuAddFlag) {
                this.tabMenu.push(data);
              }
              this.selectMenuId = data['id'];
            })
            observable.next();
        });
      
        this.commMessService.set('myObserver', urlObserver);

        // 公共消息提示
        let myObserver: Observable<any> = Observable.create((observable) => {
            let msgSubData = this.messageSub.get('msgSub');
            if (msgSubData) {
                this.showMsg(msgSubData);
            }
            observable.next();
        });
        this.messageSub.set('msgObser', myObserver);
    }

    showCode = false
    showQcCode() {
        this.showCode = !this.showCode
    }
}


